
const Home = () => {
    return (
        <div className = "home">
            <h2>Home</h2>
        </div>
    )
    
}

export default Home